import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;

const isProduction = process.env.NODE_ENV === "production";

console.log(!isProduction);
console.log(process.env.NODE_ENV);

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_UID,
  checkDuplicatedScript: true,
  router,
  debug: {
    enabled: !isProduction,
    trace: !isProduction,
    sendHitTask: isProduction,
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#score-election-app");
