<template>
  <div id="score-election-app">
    <keep-alive>
      <router-view v-if="dataLoaded" :electionData="electionData" />
    </keep-alive>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "score-election-app",
  data: function () {
    return {
      electionData: {
        method: "FPTP",
        candidates: [
          {
            id: null,
            name: null,
            statement: null,
            photo_filename: null,
            queryStringUvn: null,
          },
        ],
      },
      dataLoaded: false,
    };
  },
  created() {
    // Connect to backend API and receive election data
    const url = process.env.VUE_APP_API_ELECTION
    axios
      .get(
          url +
          process.env.VUE_APP_ELECTION_ID
      )
      .then((response) => {
        this.electionData = response.data;
        this.dataLoaded = true;
      })
      .catch((error) => {
        console.warn("Failed to get election data");

        // GA event tracking to log error
        this.$ga.event({
          eventCategory: "Error",
          eventAction: "Axios call: Failed to get election data",
          eventLabel: "Election ID: " + process.env.VUE_APP_ELECTION_ID,
        });

        // Alert user to error
        alert(
          "Cannot connect to election portal system. \nYou can try restarting your browser, or try again later."
        );
      });

    // Get UVN (Unique Voter Number) from query string
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.queryStringUvn = params.get("uvn");

    // Add UVN to cookie
    if (this.queryStringUvn !== null) {
      document.cookie = "uvn=" + this.queryStringUvn;
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/vars.scss";

#score-election-app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: $global-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 600px;
  padding: 0;
  margin: 0 auto 2em;

  @import "@/styles/global.scss";
}
</style>
