<template>
	<div class="ep__timeline">
		<div class="ep__timeline__container">
			<div class="ep__timeline__object">
				<div class="ep__timeline__object__counter" :class="{ 'ep__timeline__object__counter--current' : isPhase1 }">
					<span>1</span>
				</div>
				<div class="ep__timeline__object__content">
					<p class="ep__timeline__object__date">{{ new Date(electionStartDate).toLocaleDateString('en-GB', dateOptions)
          }}</p>
					<p>
						<strong>The Ballot opens</strong>
						<br />You can only vote for two candidates. If you vote for more than two, your votes will not be counted.
					</p>
				</div>
			</div>
			<div class="ep__timeline__object">
				<div class="ep__timeline__object__counter" :class="{ 'ep__timeline__object__counter--current' : isPhase2 }">
					<span>2</span>
				</div>
				<div class="ep__timeline__object__content">
					<p class="ep__timeline__object__date">{{ new Date(electionEndDate).toLocaleDateString('en-GB', dateOptions)}} at 5.00pm</p>
					<p>
						<strong>The Ballot closes</strong>
						<br />Digital Ballots can be submitted online up to this deadline. Paper Ballots received after this date will not be counted.
					</p>
				</div>
			</div>
			<div class="ep__timeline__object">
				<div class="ep__timeline__object__counter" :class="{ 'ep__timeline__object__counter--current' : isPhase3 }">
					<span>3</span>
				</div>
				<div class="ep__timeline__object__content">
					<p class="ep__timeline__object__date">{{ new Date(electionResultDate).toLocaleDateString('en-GB', dateOptions)}}</p>
					<p>
						<strong>Results announced</strong>
						<br />The results of the Ballot will be announced on the Scheme website in early February.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Timeline",
		props: {
			electionData: Object,
		},
		computed: {
			electionStartDate: function () {
				return new Date(this.reformatDate(this.electionData.start)).getTime()
			},
			electionEndDate: function () {
				return new Date(this.reformatDate(this.electionData.end)).getTime()
			},
			electionResultDate: function () {
				return new Date(this.reformatDate(this.electionData.result)).getTime()
			},
			isPhase1: function() {
				if ( Date.now() > this.electionStartDate && Date.now() < this.electionEndDate ) {
					return true;
				} else {
					return false;
				}
			},
			isPhase2: function() {
				if ( Date.now() > this.electionEndDate && Date.now() < this.electionResultDate ) {
					return true;
				} else {
					return false;
				}
			},
			isPhase3: function() {
				if ( Date.now() > this.electionResultDate ) {
					return true;
				} else {
					return false;
				}
			},
		},
    data() {
      return {
dateOptions: {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
}
      }
    },
		methods: {
			reformatDate: function (unformattedDate) {
				return unformattedDate.replace(" ", "T");
			}
		}
	};
</script>

<style scoped lang="scss">
	@import "@/styles/vars.scss";

	@keyframes colour-pulse {
		0%		{background-color: $color-1; transform: scale(1);}
		100%	{background-color: $color-2; transform: scale(1.2);}
	}

	#score-election-app {
		.ep__timeline__container {
			border-left: 4px solid $color-1;
			padding-top: 10px;
			margin-left: 30px;
		}
		.ep__timeline__object {
			display: flex;
		}
		.ep__timeline__object__counter {
			height: 40px;
			width: 40px;
			background-color: $color-1;
			color: white;
			font-weight: 700;
			font-size: 1.4em;
			border-radius: 50%;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: space-around;
			margin-left: -22px;
			flex-shrink: 0;

			&.ep__timeline__object__counter--current {
				// background-color: $color-2;
				animation: colour-pulse 2s alternate infinite;
			}
		}
		.ep__timeline__object__content {
			margin-left: 10px;
			margin-bottom: 1em;

			.ep__timeline__object__date {
				font-weight: 700;
				color: $color-2;
				font-size: 1.2em;
				line-height: 1em;
				margin-top: 10px;
			}
			p {
				margin: 0;
			}
		}
	}
</style>
