<template>
  <div class="ep__home">
  	<img class="ep__client-logo" src="@/assets/logo.png">
  	<p class="ep__intro-text">Election of a <strong>Member Nominated Director</strong> to the Trustee Board by the Active membership</p>
  	<hr>
    <router-link v-if="Date.now() > electionOpenDate && Date.now() < electionCloseDate" to="/info"><button class="ep__btn ep__btn-primary">Start <img class="ep__icon" src="@/assets/icon--go.svg" alt="Icon go"></button></router-link>
    <p v-if=" Date.now() < electionOpenDate || Date.now() > electionCloseDate "><strong>This election is not open.</strong></p>
    <Timeline
    	v-if=" Date.now() < electionOpenDate || Date.now() > electionCloseDate"
    	:electionData="electionData"
    	>
    </Timeline>
  </div>
</template>

<script>
	import Timeline from '@/components/Timeline.vue'

	export default {
		components: {
			Timeline
		},
		props: {
		 	electionData: Object
		},
		computed: {
			electionOpenDate: function() {
				let unformattedDate = this.electionData.start;
				let reformattedDate = unformattedDate.replace(" ", "T");
				return new Date(reformattedDate).getTime();
			},
			electionCloseDate: function() {
				let unformattedDate = this.electionData.end;
				let reformattedDate = unformattedDate.replace(" ", "T");
				return new Date(reformattedDate).getTime();
			}
		}
	}
</script>

<style scoped lang="scss">
	@import "@/styles/vars.scss";
	#score-election-app {
		.ep__home {
			text-align: center;
		}

		.ep__client-logo {
			display: block;
			width: 200px;
			margin: 20px auto;
		}

		.ep__intro-text {
			font-size: 2em;
			margin: 0 0 1rem;

			strong {
				color: $color-1;
			}
		}

		a, a:active, a:hover, a:focus {
			text-decoration: none;
		}
		hr {
			margin: 1rem 0;
		}
	}
</style>
